import * as React from "react";

const SVGWorldIcon = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <title>Artboard 12</title>
    <path stroke="white" fill="#0070c0"  d="M10.46351,19.858H21.53874a28.57792,28.57792,0,0,0-.00052-7.70749H10.46071A28.55684,28.55684,0,0,0,10.46351,19.858Z" />
    <path stroke="white" fill="#0070c0" d="M21.17786,10.183a.18715.18715,0,0,0,.007-.04636c-.03623-.16658-.06975-.33385-.11065-.49929A15.39907,15.39907,0,0,0,18.9459,4.51667,5.32806,5.32806,0,0,0,17.16483,2.7871a2.25324,2.25324,0,0,0-2.24857-.04091,4.53739,4.53739,0,0,0-1.44159,1.21889,15.07937,15.07937,0,0,0-2.68124,6.218Z" />
    <path stroke="white" fill="#0070c0" d="M21.21089,21.81508H10.79461A15.14112,15.14112,0,0,0,13.3441,27.862a6.00914,6.00914,0,0,0,1.08671,1.07967,2.32585,2.32585,0,0,0,3.17294-.03194,8.13279,8.13279,0,0,0,1.41294-1.5519,13.24031,13.24031,0,0,0,1.81522-4.085C20.96933,22.79556,21.08262,22.31134,21.21089,21.81508Z" />
    <path stroke="white" fill="#0070c0" d="M30.04719,12.40623c-.05621-.224-.14788-.27834-.36451-.27746-1.96194.008-3.92394.00466-5.88592.00466h-.30468a31.21358,31.21358,0,0,1,.001,7.71536,1.42039,1.42039,0,0,0,.14745.01825c2.04247.0009,4.08493-.00165,6.12737.00561.19175.00068.22634-.09182.26561-.24408A14.41529,14.41529,0,0,0,30.04719,12.40623Z" />
    <path stroke="white" fill="#0070c0" d="M8.20269,19.86832h.30708a31.15682,31.15682,0,0,1-.00288-7.71419,1.279,1.279,0,0,0-.14469-.01934c-2.04254-.001-4.0851.00157-6.12763-.0057-.19052-.00068-.2277.08921-.2673.24242a14.4023,14.4023,0,0,0-.01436,7.22242c.0556.22166.14481.28019.36363.27926C4.27857,19.86484,6.24063,19.86832,8.20269,19.86832Z" />
    <path stroke="white" fill="#0070c0" d="M29.23009,10.1884c-.77833-3.27248-6.38689-7.93172-9.96932-8.27656C21.57258,4.20538,22.51663,7.1406,23.198,10.1884Z" />
    <path stroke="white" fill="#0070c0" d="M12.741,1.91283c-3.57611.336-9.04764,4.87827-9.97535,8.27124H8.80221C9.48457,7.13732,10.42608,4.21692,12.741,1.91283Z" />
    <path stroke="white" fill="#0070c0" d="M21.82933,26.24871a11.36314,11.36314,0,0,1-2.58984,3.83945c3.52748-.28464,9.21572-5.00789,9.99192-8.274H23.18866A21.86519,21.86519,0,0,1,21.82933,26.24871Z" />
    <path stroke="white" fill="#0070c0" d="M2.73151,21.81233a14.58041,14.58041,0,0,0,4.04061,5.35c1.63949,1.37018,4.98019,2.996,5.94138,2.89651-2.2875-2.27468-3.229-5.19553-3.91016-8.24654Z" />
  </svg>
);

export default SVGWorldIcon;
