import { useState,useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import heartImage from "../../assets/images/shapes/heart-2-1.png";
import aboutImage from "../../assets/images/resources/about-counter-1-1.jpg";
import aboutHeart from "../../assets/images/shapes/about-count-heart-1-1.png";
import styles from "./style.module.css";
import useTranslation from "next-translate/useTranslation";

const AboutCounter = () => {
  const { t, lang } = useTranslation();

  const [language, setLanguage] = useState();

  useEffect(() => {
    getLanguages();
  }, [lang]);

  const getLanguages = async () => {
    if (lang === "tr") {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  };

  const [counter, setCounter] = useState({
    startCounter: false,
  });

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setCounter({ startCounter: true });
    }
  };

  return (
    <section className="about-counter " style={{paddingTop:"7%"}}>
      <Container>
        <Row>
          <Col lg={6}>
            <div className="block-title" style={{marginTop:"15%"}}>
              {" "}
              <p>
                <img src={heartImage} width="15" alt="" />
                {t("common:Hayalinizi yörüngeye göndelim!")}
              </p>
              <h2 style={{ paddingTop: "20px" }} className={styles.youCanShare}>
                {t("common:Hayalinizi Bizimle Paylaşın!")}
              </h2>
            </div>
            <p className="about-counter__text">
              {t("common:Hayallere farkındalık yaratmak")} {!language && (
                  <a
                    href="https://twitter.com/hayalimuzayda"
                    target="_blank"
                    style={{ color: "blue", fontWeight: "bold" }}
                  >
                    {" "}
                    #hayalimuzayda {" "}
                  </a>


                )}
               { t("common:to encourage")}
              
            </p> 
            <ul className="list-unstyled ul-list-two">
              <div style={{color:"var(--thm-black)"}}>{t("common:All kinds of dreams")}</div>
              
            </ul>
            
          </Col>
          <Col lg={6}>
            <div className="about-counter__image clearfix">
              {/* <div className="about-counter__image-content">
                
                <p style={{ color: "black" }}>
                  All process takes less than a minute
                </p>
              </div> */}
              <img src={aboutImage} alt="" className="float-left" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutCounter;
