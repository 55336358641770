import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const CallToAction = () => {

  const { t, lang } = useTranslation();
  return (
    <section className="" style={{paddingTop:"5%",paddingBottom:"5%"}}>
      <Container >
        <Row className="align-items-center">
          <Col xl={8} lg={8}>
            <div className="">
              
              <h3 className="text-center">
              {t("common:Couldn't solve your problem?")}
              </h3>
            </div>
          </Col>
          <Col
            xl={3}
            lg={3}
            className="d-flex justify-content-center " 
            style={{paddingLeft:"0"}}
          >
            <Link href="mailto:join@hayalimuzayda.com" >
              <a className="thm-btn1 ">{t("common:Contact Us")}</a>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CallToAction;
